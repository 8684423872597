<template>
  <v-data-table :loading="loading" :headers="headers" :items="items">
  </v-data-table>
</template>

<script>
import { saveChildRoute } from '@/router/helper';

export default {
  name: 'PersonGroups',
  props: {
    id: { type: String },
  },
  computed: {
    personId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Typ', value: 'type.description' },
      ],
      items: [],
      loading: false,
    };
  },
  methods: {
    async getData() {
      if (this.personId) {
        this.loading = true;
        this.items = await this.apiList({
          resource: 'account/group',
          query: 'person=' + this.personId,
        });
        this.loading = false;
      }
    },
  },
  watch: {
    async id() {
      await this.getData();
    },
  },
  async created() {
    await this.getData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
};
</script>
